import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Prisma: westack {
      tool(id: "prisma") {
        ...ToolInfo
      }
    }
    Kraken: westack {
      tool(id: "kraken") {
        ...ToolInfo
      }
    }
    Terser: westack {
      tool(id: "terser") {
        ...ToolInfo
      }
    }
    Github1s: westack {
      tool(id: "github1s") {
        ...ToolInfo
      }
    }
    Sucrase: westack {
      tool(id: "sucrase") {
        ...ToolInfo
      }
    }
    Fnm: westack {
      tool(id: "fnm") {
        ...ToolInfo
      }
    }
    Fselect: westack {
      tool(id: "fselect") {
        ...ToolInfo
      }
    }
    Git_quick_statistics: westack {
      tool(id: "git-quick-stats") {
        ...ToolInfo
      }
    }
    Grain: westack {
      tool(id: "grain") {
        ...ToolInfo
      }
    }
    Wasm3: westack {
      tool(id: "wasm3") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Prisma.tool} mdxType="Tool" />
    <Tool tool={props.data.Kraken.tool} mdxType="Tool" />
    <Tool tool={props.data.Terser.tool} mdxType="Tool" />
    <Tool tool={props.data.Github1s.tool} mdxType="Tool" />
    <Tool tool={props.data.Sucrase.tool} mdxType="Tool" />
    <Tool tool={props.data.Fnm.tool} mdxType="Tool" />
    <Tool tool={props.data.Fselect.tool} mdxType="Tool" />
    <Tool tool={props.data.Git_quick_statistics.tool} mdxType="Tool" />
    <Tool tool={props.data.Grain.tool} mdxType="Tool" />
    <Tool tool={props.data.Wasm3.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      